<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Cambio de tipo</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="pa-4">
      <span class="text-h6">Estudio: {{ estudio }}</span>

      <div class="d-flex flex-wrap">
        <TipoEstudio v-for="(tipo, i) in tiposEstudios" :key="i" :tipo="tipo" @click.stop="nuevoTipo = tipo" />
      </div>

      <template v-if="nuevoTipo">
        <v-divider></v-divider>
        <div class="d-flex align-center">
          <v-card-title> Cambiar tipo a : </v-card-title>
          <TipoEstudio :tipo="nuevoTipo" />
          <v-spacer></v-spacer>
          <v-btn @click.stop="cambiarTipo" color="primary" outlined
            >Aceptar</v-btn
          >
        </div>
      </template>
    </div>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    TipoEstudio: () => import("./TipoEstudio.vue"),
  },
  props: {
    estudio: String | Number,
    interno: Boolean,
  },
  data() {
    return {
      nuevoTipo: null,
      tiposEstudios: [],
    };
  },
  async mounted() {
    try {
      var { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/tipos?disabled____list=false`,
      });
      this.tiposEstudios = data;
    } catch (e) {
      console.log(e);
      this.$root.$emit("snack", "Ha ocurrido un error");
    }
  },
  methods: {
    async cambiarTipo() {
      if (
        !(await this.$refs.confirm.open(
          "Cambiar tipo",
          "¿Estás seguro de que quieres cambiar estos estudios a " +
            this.nuevoTipo.nombreTipo +
            "?"
        ))
      )
        return;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/estudios/cambiar_tipo`,
        data: {
          idEstudio: this.estudio,
          idTipo: this.nuevoTipo.idTipo,
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha cambiado el tipo con éxito");
          this.nuevoTipo = null
          this.$emit("close");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido cambiar el tipo");
        });
    },
  },
};
</script>

<style></style>
